// import VueSocketIO from 'vue-socket.io'
// import SocketIO from 'socket.io-client'

// const socketconnection = SocketIO(`http://${window.location.hostname}:5050`, {
//   query: { token: localStorage.getItem('token') || null },
//   autoConnect: false
// })

import moment from "moment";
import api from "./api.js";
import Notiflix from "notiflix";
import VueUniversalModal from "vue-universal-modal";

import "@fortawesome/fontawesome-free/js/all.js";
import "vue-universal-modal/dist/index.css";
import "@/assets/sass/components/_universal.modals.scss";

Notiflix.Notify.init({
  position: "center-top",
});

import {
  reportFail,
  notifyFail,
  reportInfo,
  reportSuccess,
  notifySuccess,
} from "./idbrics.extras.js";

import drag from "v-drag";

// Sweet Alert 2
// import Swal from "sweetalert2";
// import "sweetalert2/src/sweetalert2.scss";
// import '~@sweetalert2/theme-bulma/bulma.scss';
// import '@sweetalert2/theme-bulma/bulma.css'

// Apex Charts
// import VueApexCharts from 'vue-apexcharts'

// Universal modals
import mitt from "mitt";
const emitter = mitt();

import "vue-select/dist/vue-select.css";

// import mitt from 'mitt'

export default {
  install(Vue) {
    // Vue.use(new VueSocketIO({
    //   debug: true,
    //   autoConnect: false,
    //   connection: socketconnection,
    //   vuex: {
    //       store,
    //       actionPrefix: 'SOCKET_',
    //       mutationPrefix: 'SOCKET_'
    //   },
    // }))

    // Vue.use(Buefy, {
    //   defaultIconPack: "fas"
    // })

    // Vue.use(VueApexCharts)
    // Vue.component('apexchart', VueApexCharts)

    Vue.config.globalProperties.$emitter = emitter;
    Vue.provide("emitter", emitter);

    Vue.config.globalProperties.$backend = process.env.VUE_APP_BACKEND_URL;

    Vue.config.globalProperties.$logo = {
      icon: "/media/logos/icon.png",
      full: "/media/logos/logo-white.png",
      square: "/media/logos/logo-square.png",
    };

    Vue.config.globalProperties.$moment = moment;

    Vue.config.globalProperties.$api = api;

    Vue.config.globalProperties.$notiflix = Notiflix;
    Vue.config.globalProperties.$noty = {
      reportFail,
      notifyFail,
      reportInfo,
      reportSuccess,
      notifySuccess,
    };

    Vue.use(VueUniversalModal, {
      teleportTarget: "#universal-modals",
    });

    Vue.use(drag);

    Vue.config.globalProperties.$backendUrl = process.env.VUE_APP_BACKEND_URL;

    // SECTION Filters
    Vue.config.globalProperties.$filters = {
      // ANCHOR Format Number
      formatNumber(value, dec = 0, symbol = "") {
        if (isNaN(value)) return "-";
        if (value == 0 && dec <= 0) return "-";

        let options = {
          style: "decimal",
          currency: "USD",
          minimumFractionDigits: dec,
          maximumFractionDigits: dec,
        };
        let formatter = new Intl.NumberFormat("en-US", options);
        return symbol + formatter.format(value);
      },

      // ANCHOR Format Date
      formatDate(value, format = null) {
        if (!value) return null;
        if (format) return moment(value).format(format);

        return moment(value).format("DD/MM/YYYY");
      },

      // ANCHOR Format DateTime
      formatDateTime(value, format = null) {
        if (!value) return null;
        // console.log("HORARIO VERANO", moment(value).isDST());
        if (format) return moment(value).format(format);

        return moment(value).format("DD/MM/YYYY HH:mm");
      },
      formatDateString(value) {
        const options = {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
          timeZone: "UTC",
        };
        return new Date(value).toLocaleDateString("es-MX", options);
      },
    };

    // Vue.prototype.$swal = Swal;
    // Vue.config.globalProperties.$swal = Swal;

    // Vue.filter("phone", function (phone) {
    //   if (!phone) return "";

    //   return phone
    //     .replace(/[^0-9]/g, "")
    //     .replace(/(\d{2})(\d{4})(\d{4})/, "$1-$2-$3");
    // });
  },
};
