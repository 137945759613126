import Notiflix from "notiflix";

const retryveError = (error) => {
  console.error(error);
  if (typeof error === "string") {
    return error;
  }

  if (error.message == "Network Error") {
    return "Error de red/timeout";
  }

  if (error.response.status == 400) {
    if (error.response.data["message"] !== undefined) {
      return error.response.data.message;
    }

    if (Array.isArray(error.response.data.errors)) {
      return error.response.data.errors[0];
    }
  }

  if (error.response.status == 403) {
    if (error.response.data["message"] !== undefined) {
      return error.response.data.message;
    }

    if (Array.isArray(error.response.data.errors)) {
      return error.response.data.errors[0];
    }
  }

  if (error.response.status == 401) {
    return "No autorizado";
  }

  if (error.response.status == 404) {
    return "Recurso no encontrado";
  }

  if (error.response.status == 422) {
    console.log(error)
    return error.response.data.errors[0];
  }

  if (error.response.status == 500) {
    return "Error interno del servidor";
  }

  if (error.response.status == 503) {
    return "Servicio no disponible";
  }

  if (error.response.status == 504) {
    return "Tiempo de espera agotado";
  }

  if (error.response.status == 0) {
    return "Error de conexión";
  }

  // return `${error.response.status}: ${error.response.statusText}`;

  return "Error desconocido";
};

const reportFail = (error) => {
  console.log(typeof error, error);

  Notiflix.Report.failure(
    "Error en Petición",
    retryveError(error),
    "Entendido"
  );
};

const reportCatch = (error) => {
  let message = "";

  if (typeof error === "string") {
    message = error;
  }

  // if (typeof error.response.data.errors === "object") {
  //   message = error.response.data.errors[0];
  // }

  Notiflix.Report.warning("Error Interno", message, "Entendido");
};

const reportInfo = (message, title = "Información") => {
  Notiflix.Report.info(title, message, "Ok");
};

const reportSuccess = (message, title = "Completado") => {
  Notiflix.Report.success(title, message, "Ok");
};

const notifyFail = (error) => {
  Notiflix.Notify.failure(retryveError(error));
};

const notifySuccess = (message) => {
  Notiflix.Notify.success(message);
};

const buttonKtIndicator = (ref, state = null) => {
  console.log(ref);
  if (!ref) {
    reportCatch("Error interno al intentar cambiar el estado del botón.");
    return false;
  }

  if (
    (!ref.value || ref.value?.getAttribute("data-kt-indicator") == "on") &&
    state == null
  )
    return false;

  if (state == null) return;

  if (state == "on") {
    ref.value.setAttribute("data-kt-indicator", state);
    ref.value.setAttribute("disabled", state);
  }

  if (state == "off") {
    if (!ref.value) return;
    setTimeout(() => {
      if (!ref.value) return;
      ref.value.setAttribute("data-kt-indicator", state);
      ref.value.removeAttribute("disabled");
    }, 500);
  }
};

export {
  Notiflix,
  reportFail,
  notifyFail,
  reportInfo,
  reportSuccess,
  notifySuccess,
  buttonKtIndicator,
};
