import { configure } from "vee-validate";
import { localize } from "@vee-validate/i18n";
import es from "@vee-validate/i18n/dist/locale/es.json"; // Importar traducciones en español

export function initVeeValidate() {
  // Configurar vee-validate
  configure({
    validateOnBlur: true,
    validateOnChange: true,
    validateOnInput: true,
    validateOnModelUpdate: true,
    // Agregar configuración para i18n
    generateMessage: localize({
      es, // Puedes agregar más idiomas aquí
    }),
  });

  // Establecer el idioma por defecto
  localize("es"); // Cambiar 'es' por el código del idioma deseado
}
